<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-chip
        small
        close
        v-bind="attrs"
        @click:close="$emit('remove', organism)"
        v-on="on"
      >
        {{ organism.name }}
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('Sensitivities') }}: {{ organism.name }}
        <v-spacer />
        <v-btn
          icon
          tile
          color="grey"
          @click.stop="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-data-table
        :items="sensitivities"
        :headers="[
          { text: $t('Antibiotic'), value: 'drug', sortable: false },
          { text: $t('Sensitivities'), value: 'result', sortable: false },
          { text: 'MIC (μg/mL)', value: 'mic', width: '120', sortable: false }
        ]"
        fixed-header
        height="400"
        hide-default-footer
        sort-by="drug"
        disable-pagination
        dense
        :search="search"
      >
        <template #item.result="{ item }">
          <v-btn-toggle
            v-model="item.result"
            group
            :color="buttonColor(item.result)"
          >
            <v-btn
              v-for="option in ['S', 'I', 'R']"
              :key="option"
              :value="option"
              small
            >
              <v-icon x-small>
                fa-solid fa-{{ option == '?' ? 'question' : option.toLowerCase() }}
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
        <template #item.mic="{ item }">
          <v-text-field
            v-model="item.mic"
            dense
            single-line
            hide-details
            outlined
            class="my-1"
          />
        </template>
        <template #footer>
          <v-divider />
          <v-row
            justify="space-between"
            class="pa-2"
          >
            <v-col cols="4">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :label="$t('Search')"
                dense
                single-line
                clearable
                hide-details
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="!add_drug"
                x-small
                outlined
                @click="add_drug = true"
              >
                {{ $t('Add') }}
              </v-btn>
              <v-text-field
                v-else
                v-model="drug_search"
                hide-no-data
                dense
                outlined
              >
                <template #append>
                  <v-btn
                    :disabled="!drug_search"
                    x-small
                    text
                    @click="drugs.push(drug_search), drug_search = '', add_drug = false"
                  >
                    {{ $t('Add') }}
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          text
          small
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="sensitivities = sensitivities.filter(s => s.result != null), $emit('update', organism), dialog = false"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    organism: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
    add_drug: false,
    drug_search: '',
    search: '',
    drugs: [],
  }),
  computed: {
    sensitivities: {
      get () {
        let drugs = this.drugs.map(d => ({ drug: d, result: null, mic: null }))

        if (this.organism.sensitivities.length == 0) {
          return drugs
        }

        return drugs.filter(
          x => !this.organism.sensitivities.some(d => d.drug == x.drug),
        )
        .concat(this.organism.sensitivities)
      },
      set (val) {
        this.organism.sensitivities = val
      },
    },
  },
  mounted () {
    if (!this.drugs.length) {
      this.fetchDrugs()
    }
  },
  methods: {
    buttonColor (result) {
      switch (result) {
        case 'S':
          return 'success'
        case 'I':
          return 'grey'
        case 'R':
          return 'error'
      }
    },
    fetchDrugs () {
      this.loading = true
      this.axios.get('admin/drugs', {
        params: {
          sensitivitiesOption: true,
          quick: true,
          count: 100,
        },
      })
        .then((res) => {
          this.drugs = res.data.data.map(d => d.name[this.$i18n.locale] || d.name.en)
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          if (this.value?.id && !this.drugs.includes(this.value)) {
            this.drugs.push(this.value)
          }
        })
    },
  },
  // mounted () {
  //   if (this.organism.sensitivities.length == 0) {
  //     this.organism.sensitivities = this.sensitivities
  //   }
  // },
}
</script>
